import dispatchEvent from '~/public/assets/javascripts/dispatch-event';

/**
 * Check height of treated right rail ads and make sure they are in
 * a container 600px greater height. Also adds more such ads if needed
 * to fill right rail until it ends at bottom recirc
* */
const isUS = window.HP.params.edition === 'us';
const isUK = window.HP.params.edition === 'uk';
const {
  isEntry,
  isFront,
  isPreview,
  isApageFlex,
  features,
} = window.HP.params;
const { adsFree } = features;
const isValidPage = !adsFree && (isEntry || isFront || isApageFlex);
const rightRail = document.querySelector('.right-rail-sticky');

if ((isUS || isUK) && isValidPage && !!rightRail && !isPreview) {
  const lastStickyAd = rightRail.querySelector('.ad-right-rail-lower.sticky.last');
  const stickyAdTemplate = document.getElementById('sticky-ad-template');
  const entryContent = document.querySelector('.entry__content-list-container');
  const apageContent = document.querySelector('.js-content-for-sticky-ads');
  const stickyStartEl = document.getElementById('sticky-marker');
  const availableHeightEl = isFront || isApageFlex ? apageContent : entryContent;
  const newAdSpace = 1250;
  const bottomAdHeight = 600;

  const injectAds = () => {
    const stickyStartElOffsetTop = stickyStartEl ? stickyStartEl.offsetTop : 0;
    const availableHeight = availableHeightEl.getBoundingClientRect().height;
    const numNewAds = Math.floor((availableHeight - stickyStartElOffsetTop - bottomAdHeight) / newAdSpace);

    if (isFront || isApageFlex) {
      rightRail.style.height = `${availableHeight}px`; // need to set NUMERIC height value for last sticky ad (whose parent is rightRail) to stick
    }

    for (let i = 0; i < numNewAds; i += 1) {
      const newStickyAd = stickyAdTemplate.content.cloneNode(true);
      rightRail.insertBefore(newStickyAd, lastStickyAd);

      const ad = (rightRail.children[rightRail.children.length - 2]).children[0];
      dispatchEvent('ad-injected', { ad });
    }
  };

  setTimeout(injectAds, 5000); // 5 sec to wait for page to adjust size from ads, iframes, etc
}
