import mapiRequest from '~/public/assets/javascripts/mapi';

let profilePromise;

export default function getProfile(forceFetch = false) {
  if (!profilePromise || forceFetch) {
    profilePromise = mapiRequest('user/profile')
      .then(({ profile }) => profile)
      .catch(() => {
        const fpCookiePresent = document.cookie.includes('fp.auth');
        const mctCookiePresent = document.cookie.includes('mct');
        if (fpCookiePresent) {
          // if fp cookie is present and profile call fails it is likely due to lingering session cookie, or ianctive state expire it.
          document.cookie = 'fp.auth=; expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=.huffpost.com;';
        }
        if (mctCookiePresent) {
          // if mct cookie is present and profile call fails, expire it.
          document.cookie = 'mct=; expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=.huffpost.com;';
          localStorage.removeItem('mct-refreshed');
        }
        return null;
      });
  }
  return profilePromise;
}
