import qs from '~/public/assets/javascripts/util/qs';

class MapiError extends Error {
  constructor(resp, error) {
    super(`Mapi Error: ${error.message}`);
    Error.captureStackTrace(this, MapiError);
    this.name = this.constructor.name;
    this.resp = resp;
    this.clientMessage = error.message;
  }
}

const mapiEnv = typeof window === 'object'
  && window.HP
  && window.HP.mapiEnv;

const pageEnv = typeof window === 'object' && /^www/.test(window?.location?.hostname) ? 'production' : 'staging';

const mapiHost = (mapiEnv === 'production' || pageEnv === 'production')
  ? 'mapi.huffpost.com'
  : 'staging.mapi.huffpost.com';

export default async function mapiRequest(path, init = {}, options = {}) {
  const { method = 'POST', anonymous = 'disallow' } = options;
  const loggedOut = !document.cookie.includes('hp-auth-session=') && !document.cookie.includes('hp-authd=');
  const fpCookiePresent = document.cookie.includes('fp.auth');
  const mctCookiePresent = document.cookie.includes('mct');

  if (anonymous !== 'allow' && loggedOut) {
    // eslint-disable-next-line no-console
    console.warn(`Warning: mapi request with path ${path} not being sent`);
    if (fpCookiePresent) {
      // if fp cookie is present and user is logged out means its lingering session cookie, expire it.
      document.cookie = 'fp.auth=; expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=.huffpost.com;';
    }
    if (mctCookiePresent) {
      // if mct cookie is present and user is logged out means its lingering session cookie, expire it.
      document.cookie = 'mct=; expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=.huffpost.com;';
      localStorage.removeItem('mct-refreshed');
    }
    return null; // this matches what we do serverside
  }
  /* eslint-disable-next-line no-param-reassign */
  path = path.indexOf('/') === 0 ? path.slice(1) : path;
  const edition = init.edition ? init.edition : 'us';
  const requestObj = {
    credentials: 'include',
    headers: {
      Accept: 'application/json',
    },
  };
  if (init.body) {
    requestObj.method = method;
    requestObj.headers['Content-Type'] = 'application/json';
    requestObj.body = JSON.stringify(init.body);
  }
  const queryString = qs({ device: 'desktop', ...init.queryParams });
  const response = await fetch(`https://${mapiHost}/mapi/v4/${edition}/${path}?${queryString}`, requestObj);
  if (response.ok) {
    return response.json();
  }
  let message;
  try {
    message = await response.json();
  } catch (_) {
    // Satisfy eslint
  }
  throw new MapiError(response, message);
}

