import { adShieldScript } from '@buzzfeed/bf-utils';
import abeagle from './abeagle';

const testSlug = 'RT-1562-AdShield-script-on-Huffpost';
const isAdsFree = window.HUFFPOST?.params?.features?.adsFree;
const isShoppingPage = window.HP?.entry?.sectionSlug === 'huffpost-shopping'
                      || window.HP?.params?.slug === 'huffpost-shopping';

if (!isAdsFree && window.HP.params.abeagle && Array.isArray(window.HP.params.abeagle) && window.HP.params.abeagle.includes(testSlug)) {
  document.addEventListener('abeagleEventCambria', () => {
    const variant = abeagle.getExperimentVariant(testSlug);
    if (variant === 'on') {
      // adShieldScript will not be locaded for users from UK/Ireland / Japan
      // logic of the geo excluding is in the adShieldScript module
      adShieldScript.init({
        isShopping: isShoppingPage,
        destination: 'huffpost',
      });
    }
  }, { once: true });
}
