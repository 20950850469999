/* eslint-disable no-console, template-curly-spacing */
import getProfile from '~/public/assets/javascripts/profile';
import mapiRequest from '~/public/assets/javascripts/mapi';

const {
  isMemberPage,
} = window.HP.params;
const isUS = window.HP.params.edition === 'us';
const loyaltyTiers = window.HP?.params?.features?.loyaltyTiers ?? [];
const contributorRoles = ['contributor_monthly', 'contributor_annually', 'contributor_once', 'contributor_canceled'];
// const webAdFreeRoles = ['tier2', 'tier3'];

const isValidEmail = (email) => email && typeof email === 'string' && email.includes('@');

const getCookieValue = (name) => (
  document.cookie.match(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`)?.pop() || ''
);

const fetchFPUserData = async (profileData) => {
  try {
    // expire fp.auth if present since the cookie is set when fetching the user data
    const fpCoookiePresent = document.cookie.includes('fp.auth');
    if (fpCoookiePresent) {
      // clear cookie before calling fp data
      document.cookie = 'fp.auth=; expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=.huffpost.com;';
      document.cookie = 'fp.auth=; expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=www.huffpost.com;';
    }

    const clientSessionId = getCookieValue('bf-xdomain-session-uuid');
    const response = await fetch('/api/flip-pay/user', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: profileData.email,
        name: profileData.name?.given,
        lastName: profileData.name?.family,
        register: true,
        browser_session_id: clientSessionId,
      }),
    });
    const respJson = await response.json();
    return respJson;
  } catch (error) {
    console.error('Error trying to get flip pay user access token and data', error);
    return null;
  }
};

const fpUserTypeToMapiRole = (fpUserData) => {
  const { type, canceled } = fpUserData;
  let role = null;
  // means user did a monthly or annually contribution but then canceled, we give the contributor canceled role
  if ((type === 'monthly' || type === 'annually') && canceled) {
    role = 'contributor_canceled';
  } else if (type) {
    role = `contributor_${type}`;
  }
  return role;
};

/**
 * Determines the action type based on the user's subscription and membership status.
 *
 * @param {boolean} fpSubscriber - Indicates if the user is a subscriber (meaning it is recurring and it is not one time), comes from Flip Pay data.
 * @param {Array<string>} mapiRoles - The roles assigned to the user, this come from mapi profile response.
 * @param {string} mapiCurrentLoyaltyTier - The current loyalty tier of the user that comes from mcapi on the profile response.
 * @param {string} fpLoyaltyTier - The loyalty tier user should have, comes from Flip Pay data.
 * @param {boolean} fpCanceled - Indicates if the last subscription is canceled, comes from Flip Pay data.
 * @param {boolean} fpRefunded - Indicates if the subscription is refunded, comes from Flip Pay data.
 * @param {string} fpRefundDate - The date when the refund was processed, comes from Flip Pay data.
 * @param {string} mcapiTierEndDate - The end date of the current membership we have registered, comes from mcapi via the mapi profile response.
 * @param {string} fpRole - The role the user should have based on the Flip Pay data in the format we have for mapi. ie: contributor_monthly
 * @param {string} fpEndDate - The end date of the subscription as Flip Pay has it.
 * @param {Object} mcapiMembership - The membership data we have in mcapi, comes from the profile response
 * @returns {string} The action type to be performed when syncing with mapi.
 */
const determineActionType = (fpSubscriber, mapiRoles, mapiCurrentLoyaltyTier, fpLoyaltyTier, fpCanceled, fpRefunded, fpRefundDate, mcapiTierEndDate, fpRole, fpEndDate, mcapiMembership) => {
  // if fpSubscriber = false it means its a one time contribution, we only need to add the contributor_once role and only if not present already
  const fpEndDateAsDate = new Date(fpEndDate);

  if (fpSubscriber === false && !mapiRoles.includes('contributor_once')) {
    return 'one_time_contribution';
  }

  // monthly and annual plans that are ongoing (not canceled or refunded)
  if (fpSubscriber === true && !fpCanceled && !fpRefunded && fpLoyaltyTier) {
    if (!mapiCurrentLoyaltyTier || (mapiRoles.includes('contributor_canceled') && fpRole !== 'contributor_canceled')) {
      // we have a membership in profile but no profile means auth0update failed but mapi went trough, lets just update the loyaltyTier
      if (mcapiMembership && mcapiMembership.hasMembership && new Date(mcapiTierEndDate).getTime() === new Date(fpEndDate).getTime()) {
        return 'add_loyalty_tier';
      }
      // if user doesn't have mapiCurrentLoyaltyTier it means membership was not created we should treat it as new
      return 'new';
    }
    // if mapiCurrentLoyaltyTier is different from the one in fpUserData (fpLoyaltyTier) it means there was a membership update, we need to update the role and update membership
    if (mapiCurrentLoyaltyTier && fpLoyaltyTier && mapiCurrentLoyaltyTier !== fpLoyaltyTier) {
      return 'change';
    }
    // if both role and loyaltyTier are present we need to check the mcapiTierEndDate, if they are different we need to update the mcapiTierEndDate
    if (mapiRoles.includes(fpRole) && loyaltyTiers.includes(mapiCurrentLoyaltyTier) && mcapiTierEndDate && new Date(mcapiTierEndDate).getTime() !== fpEndDateAsDate.getTime()) {
      return 'renew';
    }
    // this covers change from monthly to annual tier change or vice versa where loyalty tier is the same but the endDate is different and we don't have the new role in the user mapiRoles
    if (!mapiRoles.includes(fpRole) && loyaltyTiers.includes(mapiCurrentLoyaltyTier) && mcapiTierEndDate && new Date(mcapiTierEndDate).getTime() !== fpEndDateAsDate.getTime() && fpEndDateAsDate > Date.now()) {
      return 'new';
    }
  }

  // if fpSubscriber = true and fpCanceled = true (last purchase was canceled)
  if (fpSubscriber === true && (fpCanceled || fpRefunded)) {
    if (fpRefunded && (!mcapiTierEndDate || (mcapiTierEndDate && new Date(mcapiTierEndDate) > new Date(fpRefundDate)))) {
      return 'refund';
    }
    // role contributor_canceled doesn't exist, we need to update the role
    if (!mapiRoles.includes('contributor_canceled')) {
      return 'cancel';
    }
    // if role contributor_canceled is already there and user still has one of the loyalty_tiers and the mcapiTierEndDate has passed we need to remove the loyalty tier role
    if (mapiRoles.includes('contributor_canceled') && loyaltyTiers.includes(mapiCurrentLoyaltyTier) && new Date(mcapiTierEndDate) < Date.now()) {
      return 'remove_loyalty_tier';
    }
  }

  return '';
};

const maybeTriggerAppRefresh = () => {
  // Function to compare version strings
  const isVersionGreaterOrEqual = (version, target) => {
    const versionParts = version.split('.').map(Number);
    const targetParts = target.split('.').map(Number);

    for (let i = 0; i < targetParts.length; i += 1) {
      if (versionParts[i] > targetParts[i]) return true;
      if (versionParts[i] < targetParts[i]) return false;
    }
    return true;
  };
  // let's trigger app user profile fetch if we are on a webview after mapiSync
  if (window.HP.params.isWebview) {
    window.waitForGlobalCambria(
      () => window.getAppInfo,
      () => {
        window.getAppInfo().then((info) => {
          console.log('isVersionGreaterOrEqual:', isVersionGreaterOrEqual(info.appVersion, '2025.2.0'));
          // Check if app_version is equal to or above "2025_2_0" that implemented requestUserProfile
          if (info && info.appVersion && isVersionGreaterOrEqual(info.appVersion, '2025.2.0')) {
            console.log('calling requestUserProfile after mapiSync');
            window.requestUserProfile();
          }
        });
      },
      50, // poll every 50ms
      { fallback: 3000, caller: 'requestUserProfile', fallbackCb: () => { console.log('requestUserProfile: call stopped after 3 secs'); } }, // suspend wait after 3 seconds
    );
  }
};

const makeSyncMapiCall = async (bodyToPatch) => {
  try {
    await mapiRequest('user/profile/edit', { body: { ...bodyToPatch } }, { method: 'PATCH' });
    // if we don't have the mct cookie yet after a mapi patch we should call getProfile again to update the cookie
    const mctCookiePresent = document.cookie.includes('mct');
    if (!mctCookiePresent) {
      await getProfile(true);
    }
    maybeTriggerAppRefresh();
  } catch (error) {
    console.error('Error updating subscriber data:', error);
  }
};

// Calls FP api to get membership information and Syncs mapi roles with roles returned by flip pay if needed
// returns the role that was set in mapi
const syncMapi = async (fpUserData, profile) => {
  const { roles, tierEndDate, membership } = profile;
  const currentLoyaltyTier = roles.find((value) => loyaltyTiers.includes(value));
  const currentRole = roles.find((value) => contributorRoles.includes(value));

  if (fpUserData && fpUserData.data) {
    const {
      subscriber,
      type,
      canceled,
      startDate,
      endDate,
      fpCustomerId,
      fpTransactionId,
      fpPreviousTransactionEndDate,
      loyaltyTier,
      refunded,
      refundDate,
    } = fpUserData.data;
    const fpRole = fpUserTypeToMapiRole(fpUserData.data);

    // check for differences to see if we need to update the profile
    const bodyToPatch = {};

    // Determine the action type
    const actionType = determineActionType(subscriber, roles, currentLoyaltyTier, loyaltyTier, canceled, refunded, refundDate, tierEndDate, fpRole, endDate, membership);
    const startDateAsDate = new Date(startDate);
    const endDateAsDate = new Date(endDate);
    const refundDateAsDate = refundDate ? new Date(refundDate) : new Date();
    const fpPreviousTransactionEndDateAsDate = new Date(fpPreviousTransactionEndDate);
    const startDateTimestamp = Math.floor(startDateAsDate.getTime() / 1000);
    const endDateTimestamp = Math.floor(endDateAsDate.getTime() / 1000);
    const fpPreviousTransactionEndDateTimestamp = Math.floor(fpPreviousTransactionEndDateAsDate.getTime() / 1000);
    const refundDateTimestamp = Math.floor(refundDateAsDate.getTime() / 1000);

    switch (actionType) {
      case 'one_time_contribution':
        bodyToPatch.contributor_status = 'contributor_once';
        bodyToPatch.loyalty_tier = null;
        break;
      case 'change':
        bodyToPatch.contributor_status = type;
        bodyToPatch.loyalty_tier = loyaltyTier;
        bodyToPatch.newFlipPayTransactionId = fpTransactionId;
        bodyToPatch.flipPayCustomerId = fpCustomerId;
        bodyToPatch.oldEndDate = fpPreviousTransactionEndDateTimestamp;
        bodyToPatch.newStartDate = startDateTimestamp;
        bodyToPatch.newEndDate = endDateTimestamp;
        bodyToPatch.action = 'change';
        break;
      case 'new':
        bodyToPatch.contributor_status = type;
        bodyToPatch.loyalty_tier = loyaltyTier;
        bodyToPatch.endDate = endDateTimestamp;
        bodyToPatch.flipPayTransactionId = fpTransactionId;
        bodyToPatch.flipPayCustomerId = fpCustomerId;
        bodyToPatch.startDate = startDateTimestamp;
        bodyToPatch.action = 'new';
        break;
      case 'cancel':
        bodyToPatch.contributor_status = 'contributor_canceled';
        bodyToPatch.endDate = endDateTimestamp;
        bodyToPatch.action = 'cancel';
        // if the profile tierEndDate is in the past we need to remove the loyalty_tier if it has it, else we respect it until it expires
        if (tierEndDate < Date.now()) {
          bodyToPatch.loyalty_tier = null;
        }
        break;
      case 'refund':
        bodyToPatch.contributor_status = 'contributor_canceled';
        bodyToPatch.loyalty_tier = null;
        bodyToPatch.endDate = refundDateTimestamp;
        bodyToPatch.action = 'cancel';
        break;
      case 'remove_loyalty_tier':
        bodyToPatch.contributor_status = 'contributor_canceled';
        bodyToPatch.loyalty_tier = null;
        break;
      case 'renew':
        bodyToPatch.action = 'renew';
        bodyToPatch.endDate = endDateTimestamp;
        break;
      case 'add_loyalty_tier':
        bodyToPatch.contributor_status = type;
        bodyToPatch.loyalty_tier = loyaltyTier;
        break;
      default:
        break;
    }

    if (actionType) {
      makeSyncMapiCall(bodyToPatch);
    }
    return fpRole;
  }
  return currentRole;
};

const checkMembership = async (profile, hasChanged = false) => {
  const now = new Date();
  const { tierEndDate, roles } = profile;
  const tierEndDateAsDate = new Date(tierEndDate);
  const urlParams = new URLSearchParams(window.location.search);
  const authDone = urlParams.get('hp_auth_done');
  const hasTierEndDatePassed = tierEndDateAsDate && tierEndDateAsDate < now;
  // If user has just authenticated, is on the member page or has a passed tier date then fetch the FP user information
  const shouldFetchFPUserData = authDone === '1' || isMemberPage || hasTierEndDatePassed || hasChanged;
  if (shouldFetchFPUserData) {
    try {
      const fpUserData = await fetchFPUserData(profile);
      let currentRole = roles.find((value) => contributorRoles.includes(value));
      let currentLoyaltyTier = roles.find((value) => loyaltyTiers.includes(value));
      if (fpUserData && fpUserData.data && fpUserData.data.hasPurchase) {
        const { createdAt } = fpUserData.data;
        currentRole = await syncMapi(fpUserData, profile);
        currentLoyaltyTier = fpUserData.data.loyaltyTier;
        return { currentRole, createdAt, currentLoyaltyTier };
      }
      return { currentRole, createdAt: null, currentLoyaltyTier };
    } catch (error) {
      console.error('Error trying to get flip pay user access token', error);
      return null;
    }
  }
  return null;
};

const handlefpMemberCenterAction = async (event) => {
  try {
    const updateEventTypes = ['upgrade', 'downgrade', 'change_renewal_price'];
    const isUpdate = event.detail && event.detail.event_type === 'fp_user_action' && updateEventTypes.includes(event.detail.action_type) && event.detail.action_status === 'processed';
    const isCancel = event.detail && event.detail.event_type === 'fp_user_action' && event.detail.action_type === 'cancel_renewals' && event.detail.action_status === 'processed';
    const isPurchase = event.detail && event.detail.event_type === 'fp_purchase_resolved';
    const fragmentDisplayed = event && event.type === 'fp_user_event' && event.detail && event.detail.event_type === 'fp_fragment_displayed';
    const isRecurringPaymentWebview = window.location.pathname.includes('/payment-recurring/webview');
    const shouldTriggerWebviewSync = isRecurringPaymentWebview && fragmentDisplayed && event.detail.object_type === 'welcome' && event.detail.action_status === 'signed_in';

    if (isPurchase) {
      let contextPageId = 'payment_confirmed';
      if (window.location.pathname.includes('/payment-recurring')) {
        contextPageId = 'payment-recurring_confirmed';
      } else if (window.location.pathname.includes('/member')) {
        contextPageId = 'payment-member_confirmed';
      }

      const payload = { context_page_id: contextPageId, context_page_type: 'utility' };
      window.HUFFPOST.sendClientEvent(window.HUFFPOST.pageview, { ...window.HUFFPOST.tracking, ...payload });
    }
    getProfile()
      .then(async (profile) => {
        if (profile && isValidEmail(profile.email)) {
          const shouldCheckMembership = isCancel || isPurchase || isUpdate || shouldTriggerWebviewSync;
          if (shouldCheckMembership) {
            await checkMembership(profile, true);
          }
        }
      });
  } catch (error) {
    console.error('Error handling member center action:', error);
  }
};

if (isUS) {
  // susbcribe to fp events
  document.body.addEventListener('fp_user_event', handlefpMemberCenterAction);

  getProfile()
    .then(async (profile) => {
      if (profile && isValidEmail(profile.email)) {
        let currentRole = profile.roles.find((value) => contributorRoles.includes(value));
        let currentLoyaltyTier = profile.roles.find((value) => loyaltyTiers.includes(value));
        const membershipData = await checkMembership(profile);
        if (membershipData) {
          currentRole = membershipData.currentRole;
          currentLoyaltyTier = membershipData.currentLoyaltyTier;
        }
        const isContributor = contributorRoles.includes(currentRole);
        if (isContributor) {
          const buttonSupportHuffpost = document.querySelector('.front-support-huffpost__support-button, .cli-support-huffpost__support-button');
          // if logged in we can hide contributor modules based on the mapi role
          switch (currentRole) {
            case 'contributor_monthly':
            case 'contributor_annually':
              document.body.classList.add('is-contributor');
              document.body.classList.add(currentRole);
              break;
            case 'contributor_once':
              // When a logged in reader does a one-time contribution, let’s hide the modules for 2 month then show them a tailored message for a year
              document.body.classList.add(currentRole);
              if (buttonSupportHuffpost) {
                const itemNameOnce = buttonSupportHuffpost.getAttribute('data-vars-item-name');
                buttonSupportHuffpost.setAttribute('data-vars-item-name', `${itemNameOnce} (Once)`);
                buttonSupportHuffpost.removeAttribute('data-vars-item-name-overwritable');
              }
              break;
            case 'contributor_canceled':
              // When a logged in reader cancels their recurring contribution, let’s hide the modules for 1 month then show them a tailored message for a year
              // canceled contributors can still have a roll until that one expires so we should still treat them as contributors
              document.body.classList.add(currentRole);
              if (currentLoyaltyTier && ['tier1', 'tier2', 'tier3'].includes(currentLoyaltyTier)) {
                document.body.classList.add('is-contributor');
              }
              if (buttonSupportHuffpost) {
                const itemNameOnce = buttonSupportHuffpost.getAttribute('data-vars-item-name');
                buttonSupportHuffpost.setAttribute('data-vars-item-name', `${itemNameOnce} (Canceled)`);
                buttonSupportHuffpost.removeAttribute('data-vars-item-name-overwritable');
              }
              break;
            default:
              document.body.classList.add('non-contributor');
          }
        } else {
          document.body.classList.add('non-contributor');
        }
      }
    });
}
