import abeagle from './abeagle';
import session from './sessionStorage';

document.addEventListener('click', (event) => {
  // exit if clicked element has no parent or its parent has no amzn-src data-attr:
  if (!event?.target?.parentElement || !event.target.parentElement.dataset.amznSrc) {
    return;
  }

  event.preventDefault();
  const sessionVal = event.target.parentElement.dataset.amznSrc;

  session.set('hp-amzn-origin', sessionVal);
  window.location.href = event.target.parentElement.href;
});

function handleAmazonLinks(event) {
  try {
    const hpAmznOrigin = session.get('hp-amzn-origin') || 0;

    if (hpAmznOrigin !== 0) {
      event.preventDefault();
      const target = event.currentTarget;
      const href = decodeURIComponent(target.attributes.href.textContent);
      const currentLink = href.replace('hp-fil-am=0', `hp-fil-am=${hpAmznOrigin}`);
      session.remove('hp-amzn-origin');

      const parsedURL = new URL(currentLink);
      parsedURL.search = new URLSearchParams(parsedURL.search).toString();
      const affiliateURL = parsedURL.toString();
      window.location = affiliateURL;
    }
  } catch (e) {
    // Even if an error occurs don't throw an error.
    // A js error here will break amazon links.
    // eslint-disable-next-line no-console
    console.error(e);
  }
}

if (document.querySelector('body.entry')) {
  document.querySelectorAll('a[data-amazon-link]')
    .forEach((link) => link.addEventListener('click', handleAmazonLinks));
}

if (window.HP.params.isEntry && window.HP.params.abeagle && Array.isArray(window.HP.params.abeagle)) {
  document.addEventListener('abeagleEventCambria', () => {
    const experiments = abeagle.getSessionExperiments();

    const abeagleTestsParams = [];
    if (experiments && Object.keys(experiments).length > 0) {
      Object.keys(experiments).forEach((name) => {
        const experimentId = experiments[name].id;
        const variantId = experiments[name].variant_id;
        const amazonParam = variantId ? `${experimentId}:${variantId}` : '0:0';
        abeagleTestsParams.push(amazonParam);
      });
    }
    const abeagleTestsParam = abeagleTestsParams.join(';');
    if (document.querySelector('body.entry') && abeagleTestsParams.length) {
      document.querySelectorAll('a[data-amazon-link]')
        .forEach((link) => {
          const href = decodeURIComponent(link.attributes.href.textContent);
          const parsedURL = new URL(href);
          const amazonParams = parsedURL.searchParams.get('ascsubtag');
          const amazonParamsWithAbeagleTest = amazonParams.replace(',0:0,', `,${abeagleTestsParam},`);
          parsedURL.searchParams.set('ascsubtag', amazonParamsWithAbeagleTest);
          parsedURL.search = new URLSearchParams(parsedURL.search).toString();
          let parsedURLWithAbeagle = parsedURL.toString();
          // bttn logic:
          const parts = parsedURLWithAbeagle.split('&ascsubtag');
          if (parts.length > 1) {
            parts[1] = encodeURIComponent(parts[1]);
          }
          parsedURLWithAbeagle = parts.join('%26ascsubtag');
          // eslint-disable-next-line no-param-reassign
          link.attributes.href.textContent = parsedURLWithAbeagle;
        });
    }
  }, { once: true });
}
