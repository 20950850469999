export default async () => {
  let hasAdBlock;
  const runDetection = async () => {
    // Detect ad blocker
    const ad = document.createElement('div');
    ad.className = 'advertisement';
    ad.style.position = 'absolute';
    ad.style.top = '0';
    ad.id = 'special';
    ad.style.height = '1px';
    ad.style.width = '1px';
    document.body.appendChild(ad);

    // const showModal = () => {
    //   // Create overlay
    //   const overlay = document.createElement('div');
    //   overlay.classList.add('abb-overlay');

    //   // Create modal
    //   const modal = document.createElement('div');
    //   modal.classList.add('abb-modal');

    //   // Modal message
    //   const message = document.createElement('p');
    //   message.textContent = 'We detected you are using an ad-blocker. Please either sign up for ad-free access OR turn off your ad-blocker and refresh. Click below to proceed.';
    //   modal.appendChild(message);

    //   const btnContainer = document.createElement('div');
    //   btnContainer.classList.add('abb-btn-container');
    //   modal.appendChild(btnContainer);

    //   // Refresh button
    //   const refreshBtn = document.createElement('button');
    //   refreshBtn.classList.add('abb-btn-refresh');
    //   refreshBtn.classList.add('abb-btn');
    //   refreshBtn.textContent = 'Check out Ad-Free Access!';
    //   refreshBtn.onclick = () => {
    //     window.location.href = '/support';
    //   };
    //   btnContainer.appendChild(refreshBtn);

    //   // Support button
    //   const supportBtn = document.createElement('button');
    //   supportBtn.classList.add('abb-btn-refresh');
    //   supportBtn.classList.add('abb-btn');
    //   supportBtn.textContent = 'My Ad-Blocker is off!';
    //   supportBtn.onclick = () => {
    //     window.location.reload();
    //   };
    //   btnContainer.appendChild(supportBtn);

    //   document.body.appendChild(overlay);
    //   document.body.appendChild(modal);
    //   document.body.classList.add('abb-modal-open');
    // };

    await new Promise((resolve) => {
      setTimeout(() => {
        if (getComputedStyle(ad).display === 'none' || ad.offsetParent === null) {
          console.log('Ad Blocker Detected');
          hasAdBlock = true;
        } else {
          console.log('No Ad Blocker Detected');
          hasAdBlock = false;
        }
        document.body.removeChild(ad);
        resolve();
      }, 100);
    });

    return hasAdBlock;
  };

  return runDetection();

  // const startingScroll = window.scrollY;
  // const onSupportPage = window.location.pathname === '/support';

  // if (!onSupportPage) {
  // const scrollDetect = () => {
  //   if (window.scrollY - startingScroll >= 500) {
  //     runDetection();
  //     window.removeEventListener('scroll', scrollDetect);
  //   }
  // };
  // window.addEventListener('scroll', scrollDetect);
  // }
};
