import '~/public/assets/styles/non-critical-news.scss';
import './hydrator';
import './youtube';
import './membership';
import './tracky';
import addNavListener from './nav';
import './ads';
import './right-rail-sticky';
import './right-rail-sticky-ads';
import './openable';
import './b-page-newsletter';
import './hp';
import './spotim';
import './share';
import './amazon';
import './getClientUUID';
import './abeagle';
import './liveramp';
import './abAdShieldtest';
import './abOutbrainTest';
import './recirc';
import './cmp-util';
import './frontpage';
import './heartbeat';

import getProfile from './profile';

addNavListener();

window.getProfile = getProfile;
