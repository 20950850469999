import storage from '~/public/assets/javascripts/storage';

const aDay = 1000 * 60 * 60 * 24; // 1 day in ms
const today = Math.round(new Date().valueOf() / aDay);

function getCohortListsObj() {
  return storage.get('cohort_lists') || {};
}

function getCohortLists() {
  return Object.keys(getCohortListsObj());
}

function getList(list) {
  return storage.get(`cohort-${list}`) || {};
}

function setList(list, data) {
  const cohortLists = getCohortListsObj();
  cohortLists[list] = true;
  storage.set('cohort_lists', cohortLists);
  storage.set(`cohort-${list}`, data);
}

function add(list, count) {
  const data = getList(list);
  data[today] = (data[today] || 0) + count;
  setList(list, data);
  return data[today];
}

function increment(list) {
  add(list, 1);
}

function getListSum(list) {
  const data = getList(list);
  let sum = 0;
  Object.keys(data).forEach((key) => {
    sum += data[key];
  });
  return sum;
}

function decimalParseInt(string) {
  return parseInt(string, 10);
}

function lessThanMonthOld(date) {
  return (today - 31) >= date;
}

function allLists() {
  return getCohortLists().reduce((memo, item) => {
    // eslint-disable-next-line no-param-reassign
    memo[item] = getList(item);
    return memo;
  }, {});
}

function clean(list) {
  const data = getList(list);
  const days = Object.keys(data).map(decimalParseInt);
  days.filter(lessThanMonthOld).forEach((date) => {
    delete data[date];
  });
  setList(list, data);
}

function startDate() {
  return storage.get('cohort_start') || Math.min(...Object.keys(getList('pv'))) || today;
}

function age() {
  return today - startDate();
}

function cleanAll() {
  getCohortLists().forEach((list) => {
    clean(list);
  });
}

function getMostVisitedPages({ prefix = '', exclude = [], limit = null } = {}) {
  const lists = getCohortLists();
  const visits = lists
    .filter((list) => list.startsWith(prefix) && !exclude.includes(list))
    .map((list) => ({
      page: list,
      visits: getListSum(list),
    }));
  visits.sort((a, b) => b.visits - a.visits);
  return limit ? visits.slice(0, limit) : visits;
}

if (!storage.get('cohort_start')) {
  storage.set('cohort_start', startDate());
}
cleanAll();

export default {
  increment,
  add,
  total: getListSum,
  cohorts: getCohortLists,
  raw: getList,
  allRaw: allLists,
  age,
  getMostVisitedPages,
};
