import cohorts from './cohorts';
import storage from './storage';
// const subscribe = function(){};

if (storage.enabled) {
  const aDay = 1000 * 60 * 60 * 24; // 1 day in ms
  const today = Math.round(new Date().valueOf() / aDay);
  const lastPhoneHome = storage.get('lastCohortPhoneHome') || 0;
  if (lastPhoneHome < today) {
    storage.set('lastCohortPhoneHome', today);
  }

  cohorts.increment('pv');
  if (window.HP && window.HP.params && window.HP.params.isEntry) {
    cohorts.increment('b-page_pv');
  }

  if (window.HP && window.HP.params && window.HP.params.isGame) {
    cohorts.increment(`game_${window.HP.params.slug}_pv`);
  }

  const domainMatch = new RegExp(document.location.hostname, 'i');
  if (!document.referrer.match(domainMatch)) {
    cohorts.increment('session');
  }

  // let HAS_FOCUS = true;
  // window.onfocus = function() {
  //   HAS_FOCUS = true;
  // };

  // window.onblur = function() {
  //   HAS_FOCUS = false;
  // };

  // setInterval(function() {
  //   HAS_FOCUS && cohorts.add('time_on_site', 5);
  // }, 5000);

  // subscribe("read_more", function() {
  //   cohorts.increment('read_more_opened');
  // });

  // subscribe("end_of_entry", function() {
  //   cohorts.increment('finished_entry');
  // });

  // subscribe("shared", function() {
  //   cohorts.increment('shared');
  // });

  // $('body').on('click', '.card', function() {
  //   cohorts.increment('recirculated');
  // });
}
