/* eslint-disable camelcase */
import dispatchEvent from '~/public/assets/javascripts/dispatch-event';
import session from './sessionStorage';

const IS_PROD = typeof window === 'object' && window.HP && window.HP.env === 'production';
const ABEAGLE_URL = IS_PROD ? 'https://abeagle-public.buzzfeed.com/v3/experiment_variants' : 'https://abeagle-public-stage.buzzfeed.com/v3/experiment_variants';

const getSessionExperiments = () => session.get('abeagleExperiments');

const getExperiment = (name) => {
  const allExperiments = getSessionExperiments();
  const experiment = allExperiments[name] || undefined;

  if (!experiment) {
    // eslint-disable-next-line no-console
    console.error('ERROR: No ABeagle Experiment found with name:', name);
    return undefined;
  }
  return experiment;
};

const getExperimentVariant = (name) => {
  const experiment = getExperiment(name);
  return experiment?.value;
};

window.getExperimentVariant = getExperimentVariant;

const publishEvent = (exps = {}) => {
  const serverExperimentsEl = document.getElementById('server-experiment-data');
  let serverExperiments = {};
  if (serverExperimentsEl) {
    // For server experiments, the only data available will be the "value" (variant);
    //  add dummy data for other fields
    serverExperiments = JSON.parse(serverExperimentsEl.innerHTML);
    Object.keys(serverExperiments).forEach((name) => {
      serverExperiments[name].id = 0;
      serverExperiments[name].variant_id = 0;
      serverExperiments[name].version = 0;
    });
  }

  const allExps = { ...exps, ...serverExperiments };

  if (window.HP.params.abOutbrainTest.doNotPublishEvent) {
    delete allExps.hp_outbrain_remove_test;
  }

  // experiment_name|experiment_id|experiment_version|experiment_variant_name|experiment_variant_id
  const experiment_id = Object.keys(allExps)
    .map((exp) => `${exp}|${allExps[exp].id}|${allExps[exp].version}|${allExps[exp].value}|${allExps[exp].variant_id}`);
  if (!IS_PROD) {
    // eslint-disable-next-line no-console
    console.log('ABEAGLE:', experiment_id);
  }
  // Note: GAM library when abeagle test or feature is set it sends event abeagleEvent
  dispatchEvent('abeagleEventCambria', { experiment_id });
};

const fetchAbTest = async () => {
  const sessionExperiments = getSessionExperiments();
  const uuid = window.HUFFPOST.params.clientUUID;

  const clientTestsRunning = window.HP.params.abeagle?.length;
  const serverTestsRunning = window.HP.params.serverAbeagle?.length;

  // START AB QUERY PARAMS OVERRIDE
  const urlParams = new URLSearchParams(window.location.search);
  const testName = urlParams.get('test');
  const testId = urlParams.get('test_id');
  const variantName = urlParams.get('v');
  const variantId = urlParams.get('v_id');

  const overrideExperiment = {};
  if (testName && variantName) {
    overrideExperiment[testName] = {
      id: testId || 0,
      version: 2,
      resolved: true,
      is_feature_flag: false,
      value: `${variantName}`,
      variant_id: variantId || 0,
      payload: null,
    };

    session.set('abeagleExperiments', overrideExperiment);

    publishEvent(overrideExperiment, uuid);
    // eslint-disable-next-line no-console
    console.log(`ABeagle override set for test: ${testName} with variant: ${variantName}`);
    return;
  }
  // END AB QUERY PARAMS OVERRIDE

  // if serverside tests and experiments in session storage found:
  if (serverTestsRunning && sessionExperiments) {
    publishEvent(sessionExperiments);
    return;
  }

  // if experiments in session storage already:
  if (sessionExperiments) {
    publishEvent(sessionExperiments);
    return;
  }

  // if only serverside tests running:
  if (serverTestsRunning && !clientTestsRunning) {
    publishEvent();
    return;
  }

  // no tests running (ie: international editions)
  if (!serverTestsRunning && !clientTestsRunning) {
    return;
  }

  // no experiments in storage so hit abeagle for clientside experiments:
  const urlSearchParams = new URLSearchParams({
    user_id: uuid,
    source: 'huffpost_web',
    experiment_names: window.HP.params.abeagle.join(';'),
  });

  const response = await fetch(`${ABEAGLE_URL}?${urlSearchParams}`, { method: 'GET' });

  if (response.ok) {
    const allExperiments = await response.json();
    session.set('abeagleExperiments', allExperiments);
    publishEvent(allExperiments, uuid);
  } else {
    console.error(`ABeagle fetch of ${ABEAGLE_URL}?${urlSearchParams} failed:`, response.status, response.statusText);
  }
};
const isUS = window.HP.params.edition === 'us';

const hasParams = () => window.HP.cetEnabled && window.HP.params && window.HP.params.clientUUID && window.HP.params.theme;
const isCrashMonitorFlagReady = () => window.HP.params.abCrashMonitorFlag.ready;
const isOutbrainTestReady = () => !(isUS) || (!window.HP.params.isEntry) || (window.HP.params.abOutbrainTest.ready && window.HP.params.abOutbrainTest.relatedModuleReady && window.HP.params.abOutbrainTest.bottomRecirc1Ready && window.HP.params.abOutbrainTest.bottomRecirc2Ready && window.HP.params.abOutbrainTest.fetchRecircReady);

window.waitForGlobal(
  () => (hasParams() && isOutbrainTestReady() && isCrashMonitorFlagReady()),
  async () => {
    fetchAbTest();
  },
);

export default {
  getSessionExperiments,
  getExperimentVariant,
  getExperiment,
};
