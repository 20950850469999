/* eslint-disable camelcase */
const isEligible = window.HP.params?.features?.clientRecirc?.enabled;
const thisEntryId = window.HP.entry.id;

const appendQueryParam = (queryParams, key, value) => {
  const separator = queryParams.includes('?') ? '&' : '?';
  return `${queryParams}${separator}${key}=${value}`;
};

const fetchRecirc = async () => {
  const response = await fetch(`${origin}/api/recirc/entry${window.HP.params.features.clientRecirc.queryParams}`);

  if (response.ok) {
    const body = await response.json();
    // This processing to dedupe current article from recirc done here rather than in entryReircController so that we can avoid varying on recirc api endpoint per entry id:
    const bodyKeys = Object.keys(body);
    bodyKeys.forEach((key) => {
      const originalLength = body[key].length;
      body[key] = body[key].filter((card) => card?.headlines[0]?.entry_id !== thisEntryId);
      // remove extra card since no dedupe happened:
      if (body[key].length === originalLength) {
        body[key].pop();
      }
    });
    window.HP.recirc = {
      successful_fetch: true,
      ...body,
    };
  } else {
    window.HP.recirc = {
      successful_fetch: false,
    };
  }
};

if (isEligible) {
  const testSlug = 'hp_outbrain_remove_test';
  const { edition, isEntry } = window.HP.params;
  const { modulousTags } = window.HP.entry;
  const isUS = edition === 'us';

  if (isEntry && isUS) {
    const hasExcludeTag = modulousTags.includes('@nooutbrain') || modulousTags.includes('@notaboola') || modulousTags.includes('@sponsor');

    if (hasExcludeTag) {
      window.HP.params.abOutbrainTest.doNotPublishEvent = true;
    }

    if (window.HP.params.abeagle && Array.isArray(window.HP.params.abeagle) && window.HP.params.abeagle.includes(testSlug)) {
      document.addEventListener('abeagleEventCambria', () => {
        const variant = window.getExperimentVariant(testSlug);
        if (variant && !hasExcludeTag) {
          window.HP.params.features.clientRecirc.queryParams = appendQueryParam(window.HP.params.features.clientRecirc.queryParams, 'ab_test_ad', variant);
        }
        fetchRecirc();
      }, { once: true });
      window.HP.params.abOutbrainTest.fetchRecircReady = true;
    }
  } else {
    fetchRecirc();
  }
}
